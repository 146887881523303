'use client';

import React, { FC, ReactNode, useEffect } from 'react';
import { PrivyProvider } from 't2-keystone/packages/privyAuthentication/src/components/PrivyProvider';
import { FACEBOOK_PIXEL_ID, HOTJAR_CONFIG, PRIVY_APP_ID } from 'config';
import { ApolloProvider } from '@apollo/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactNotifications } from 'react-notifications-component';
import { WagmiProvider } from '@privy-io/wagmi';
import { usePathname } from 'next/navigation';
import { hotjar } from 'react-hotjar';
import { getApolloFrontendClient } from '../services/apolloClient/frontend';
import { isBrowser } from '../services/browser/isBrowser';
import { getApolloServerClient } from '../services/apolloClient/server';
import { AnalyticsProvider } from '../components/AnalyticsProvider/AnalyticsProvider';
import { FlowbiteProvider } from '../services/flowbite/FlowbiteTheme';
import { queryClient, wagmiConfig } from '../services/wagmi/wagmiConfig';
import { NotificationBellContextProvider } from '../components/NavbarNotificationBell/NavbarNotificationBellContext';
import { BannerContextProvider } from '../components/Banner/BannerContext';
import { WalletConnectionContextProvider } from '../services/walletConnection/WalletConnectionContext';

type TProvidersProps = {
  children: ReactNode;
};

export const Providers: FC<TProvidersProps> = ({ children }) => {
  const pathname = usePathname();

  useEffect(() => {
    import('react-facebook-pixel').then((module) => {
      const ReactPixel = module.default;
      ReactPixel.init(FACEBOOK_PIXEL_ID as string);
      ReactPixel.pageView();

      const handleRouteChangeComplete = () => {
        ReactPixel.pageView();
      };

      handleRouteChangeComplete();
    });
  }, [pathname]);

  useEffect(() => {
    if (HOTJAR_CONFIG?.ID && HOTJAR_CONFIG?.SV) {
      hotjar.initialize(HOTJAR_CONFIG.ID as unknown as number, HOTJAR_CONFIG.SV as unknown as number);
    }
  }, []);

  const apolloClient = isBrowser() ? getApolloFrontendClient() : getApolloServerClient();

  return (
    <ApolloProvider client={apolloClient}>
      <PrivyProvider appId={PRIVY_APP_ID as string}>
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={wagmiConfig}>
            <WalletConnectionContextProvider>
              <AnalyticsProvider>
                <FlowbiteProvider>
                  <ReactNotifications />
                  <BannerContextProvider>
                    <NotificationBellContextProvider>{children}</NotificationBellContextProvider>
                  </BannerContextProvider>
                </FlowbiteProvider>
              </AnalyticsProvider>
            </WalletConnectionContextProvider>
          </WagmiProvider>
        </QueryClientProvider>
      </PrivyProvider>
    </ApolloProvider>
  );
};
